export const environment = {
  production: false,
  api: {
    url: 'https://vc-core-api.nt-development.dev/api'
  },
  website: 'https://www.google.com',
  featuresFlag: {
    customer: true,
    exchange: true,
    voucher: true,
    bankAccounts: true,
    banks: true,
    kyc: true,
  }
};
